import { ClassNames } from "@rocketmakers/armstrong-edge";
import * as React from "react";
import * as styles from "./section.module.scss";

interface IProps {
  sticky?: boolean;
  className?: string;
  id?: string;
}
export const Section: React.FC<React.PropsWithChildren<IProps>> = ({
  sticky = true,
  children,
  className,
  id,
}) => {
  return (
    <section
      id={ClassNames.concat(id)}
      className={ClassNames.concat(
        styles.section,
        "section",
        sticky && styles.sticky,
        className
      )}
    >
      {children}
    </section>
  );
};
