import { PageProps } from "gatsby";
import * as React from "react";
import Shell from "../layout/shell";
import Logo from "../assets/quartermaster_logo.svg";
import * as styles from "./index.module.scss";
import { Section } from "../components/section";

interface IListContent {
  title: string;
  content: JSX.Element;
}

const listContent: IListContent[] = [
  {
    title: "Engage multi-\nstakeholder groups",
    content: (
      <>
        <strong>We believe collective vision underpins success.</strong> Our
        inclusive approach aims to engage with all stakeholders, including
        content producers, local communities and businesses, councils, and
        education. The positive ripple effect new studios of scale bring to
        their surrounding area is broad and considerable.
      </>
    ),
  },
  {
    title: "Leverage\n expertise",
    content: (
      <>
        <strong>We leverage our expertise with those in our network.</strong>{" "}
        Working with leading international production houses, architects, design
        teams and developers; we create best-in-class media facilities; and
        environments that embrace optimal workflow practices, occupants'
        well-being, accessibility and sustainability.
      </>
    ),
  },
  {
    title: "Match investors\n to opportunity",
    content: (
      <>
        <strong>
          We work with credible, high profile and private investment
          organisations.
        </strong>{" "}
        Our investment partners understand the industry and its culture. They
        recognise the opportunities available through investment in the creative
        sector, striking the proper investment structure to align with their
        portfolio and investment criteria.
      </>
    ),
  },
  {
    title: "Engage\n local partners",
    content: (
      <>
        <strong>
          Placemaking and working with local partners are essential components
          for success.{" "}
        </strong>
        To deliver opportunity and growth to the entertainment industry in local
        markets, we work with educational institutions and local businesses to
        create opportunities for skilled workers and to provide essential
        services to studios.
      </>
    ),
  },
];
const Index: React.FC<PageProps> = () => {
  return (
    <Shell>
      <Section className={styles.section1}>
        <header className={styles.header}>
          <img className={styles.logo} src={Logo} />
          <div className={styles.links}>
            <a href="#what-we-do">What we do</a>
            <a href="#approach">Approach</a>
            <a href="#contact">Contact</a>
          </div>
        </header>
        <h1 className={styles.title}>
          Quartermaster is a pioneer in dedicated collaborative film and
          television communities.
        </h1>
        <div className={styles.description}>
          <label>OUR VISION</label>
          <p>
            Our vision is to enhance, integrate and educate, creating innovative
            homes which not only inspire but lead creative excellence.
          </p>
        </div>
      </Section>
      <Section id="what-we-do" className={styles.section2}>
        <label>WHAT WE DO</label>
        <h2>{"Next generation\n studios"}</h2>
        <p className={styles.description}>
          Quartermaster excels as the leading innovative organisation in the UK
          in the media facility development sector, with first class
          entertainment industry specialists working together with the expertise
          of property management and developers, we strive to create bespoke
          visions to suit our clients’ requirements. Modern fit-for-purpose film
          and television studios that provide the optimal environment for
          productions and their workforce.
        </p>
      </Section>
      <Section id="approach" className={styles.section3}>
        <label>APPROACH</label>
        <h2>End-to-end Delivery</h2>
        <div className={styles.listContainer}>
          {listContent.map(({ title, content }) => (
            <div className={styles.listItem} key={title}>
              <h3>{title}</h3>
              <p>{content}</p>
            </div>
          ))}
        </div>
      </Section>
      <Section className={styles.section4}>
        <label>APPROACH</label>
        <h2>{"Industry\n expertise"}</h2>
        <p className={styles.description}>
          Quartermaster is supported by a committed team, best in class, with
          specific understanding of studios development, content production, and
          required technology advancements, to maximise successful delivery of
          its schemes. The team has been together for some time, not a pop‐up,
          with true passion and knowledge.
        </p>
      </Section>
      <Section sticky={false} className={styles.section5}>
        <div className={styles.mission}>
          <label>OUR MISSION</label>
          <p>
            We create next generation film studios fit for world–class
            filmmaking
          </p>
          <p>
            We generate economic growth and thriving communities by developing
            creative destinations. Designed by producers for producers.
          </p>
        </div>
        <footer id="contact">
          <h2>Get in touch</h2>
          <div className={styles.contactContainer}>
            <p className={styles.description}>
              We pride ourselves in the confidentiality and at times, required
              anonymity of all the parties that we work with. Strict confidence
              surrounding any workstream we are engaged to deliver is embedded
              in our DNA. We are happy to work with your NDA or can provide a
              mutual NDA.
            </p>
            <div className={styles.contact}>
              <div className={styles.details}>
                <label>CONTACT</label>
                <a href="mailto:enquiries@quartermasterentertainment.com">
                  enquiries@quartermasterentertainment.com
                </a>
                <p>
                  {
                    "Quartermaster, Salisbury House, London Wall,\n London EC2M 5PS"
                  }
                </p>
              </div>
            </div>
          </div>
        </footer>
      </Section>
    </Shell>
  );
};

export default Index;
