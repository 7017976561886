import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { HeaderQuery } from "../generated/graphql";

import "./shell.scss";

interface IShellProps {
  children: React.ReactNode;
}

const Shell: React.FunctionComponent<IShellProps> = ({ children }) => {
  // Codegen will append 'query' onto any query name so just use the root word as below.
  // Write your gql query, save the file and your typings will be generated automatically.
  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      site {
        siteMetadata {
          siteName
          description
          siteUrl
          image
        }
      }
    }
  `);

  const { siteName, siteUrl, image, description } = data.site!.siteMetadata!;

  return (
    <>
      <Helmet
        defaultTitle={siteName || ""}
        titleTemplate={`%s — ${siteName}`}
      />
      {description && (
        <>
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}
      {image && (
        <>
          <meta property="og:image" content={`${siteUrl}/${image}`} />
          <meta name="twitter:image" content={`${siteUrl}/${image}`} />
        </>
      )}

      <main>{children}</main>
    </>
  );
};

export default Shell;
